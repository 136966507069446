<template>
	<v-card class="mt-5" rounded="xl">
		<v-card-title>{{ faq.question }}</v-card-title>
		<v-card-text class="card-text text-justify" style="white-space: pre-line">
			{{ faq.answer }}
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'FAQCard',
	props: {
		faq: {
			type: Object,
			required: true,
		},
	},
}
</script>
